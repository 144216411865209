<script setup lang="ts">
import type { ComponentAlgoliaRailsProductsProps } from "./ComponentAlgoliaRailsProducts.props"
import { mapProducts } from "~/utils/mapProducts"
import { fetchLastViewedProducts } from "~/utils/fetchLastViewedProducts"
import { checkEnvSite } from "~/server/utils/getAlgoliaConfig"
import { buildExcludeFilter } from "~/utils/algolia"

const props = defineProps<ComponentAlgoliaRailsProductsProps>()

const indexProducts = useAlgoliaSearch(`TOP_PRODUCTS${checkEnvSite}`)
const initIndexProducts = useAlgoliaInitIndex(`TOP_PRODUCTS${checkEnvSite}`)
const { result, get } = useAlgoliaRecommend("algolia-rails")
const lastViewedProductsCookie: Ref<undefined | string[]> =
  useStatefulCookie("lastProductsViewed")

const checkFilter: ComputedRef<string> = computed(() => {
  switch (props.type) {
    case "OVER50%":
      return `price.discountPercentage > 50`
      break
    case "OVER20%":
      return `price.discountPercentage > 20`
      break
    case "OVER10%":
      return `price.discountPercentage: > 10`
      break
    case "UNDER50€":
      return `price.amount_cents:0 TO 5000`
      break
    case "UNDER20€":
      return `price.amount_cents:0 TO 2000`
      break
    case "UNDER10€":
      return `price.amount_cents:0 TO 1000`
      break
    case "PROMO":
      return `commercialTags:PROMO`
      break
    case "BLACK_FRIDAY":
      return `commercialTags:BLACK_FRIDAY`
      break
    default:
      return ``
      break
  }
})

const findItemsFromAlgolia = async () => {
  if (props.type) {
    return await indexProducts
      .search({
        requestOptions: {
          clickAnalytics: true,
          filters: checkFilter.value,
          hitsPerPage: 10,
          ...buildExcludeFilter({ excludeOutOfMarket: true })
        }
      })
      .then((response) => {
        const queryID = response.queryID
        const newHits = mapProducts(response.hits, queryID, "ALGOLIA")
        return newHits
      })
  } else {
    return await indexProducts
      .search({
        requestOptions: {
          clickAnalytics: true,
          facetFilters: [`${props.facetAlgolia}:${props.valueAlgolia}`],
          hitsPerPage: 10,
          ...buildExcludeFilter({ excludeOutOfMarket: true })
        }
      })
      .then((response) => {
        const queryID = response.queryID
        const newHits = mapProducts(response.hits, queryID, "ALGOLIA")
        return newHits
      })
  }
}

// @ts-ignore
const products = ref()

const ProductBlocksOutletData = computed(() => {
  return {
    title: props.title,
    description: props.subtitle,
    cta: props.cta,
    products: products.value
  }
})

const RailsProductData = computed(() => {
  return {
    title: props.title,
    text: props.subtitle ?? "",
    typeCard:
      props.type === "MOST_SOLD" || props.lastViewedProducts
        ? "MostSold"
        : "Product",
    suggestedCards: products.value,
    showAllProducts: props.cta,
    backgroundColor: "grey"
  }
})

onMounted(async () => {
  await get({
    queries: [
      {
        indexName: `TOP_PRODUCTS${checkEnvSite}`,
        model: "trending-items",
        maxRecommendations: 6,
        facetName: props.facetAlgolia,
        facetValue: props.valueAlgolia,
        queryParameters: {
          filters: "NOT price.amount_float:0.01"
        }
      }
    ]
  })

  if (props.type === "MOST_SOLD") {
    const mostSoldProducts = computed(() => {
      const products = _flattenDeep(
        result.value?.results?.map((item) => item.hits) ?? []
      )
      return mapProductsAlgolia(products)
    })

    products.value = mostSoldProducts.value
  } else if (props.lastViewedProducts && lastViewedProductsCookie.value) {
    products.value = await fetchLastViewedProducts(
      initIndexProducts,
      lastViewedProductsCookie.value
    )
  } else {
    products.value = await findItemsFromAlgolia()
  }
})
</script>

<template>
  <div class="cms-component-algolia-rails-products overflow-x-hidden">
    <ProductBlocksOutlet
      class="bg-white"
      v-if="
        ProductBlocksOutletData &&
        products?.length &&
        railType === 'ProductBlocksOutlet'
      "
      v-bind="ProductBlocksOutletData"
      :products="products"
    />

    <RailsProduct
      v-if="RailsProductData && products?.length && railType === 'RailsProduct'"
      v-bind="RailsProductData"
    />
  </div>
</template>
